import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: {
        eq: "Tworzenie-kampanii-sprzedazowej-parametry-systemu.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: {
        eq: "Tworzenie-kamapanii-sprzedazowej-lista-kampanii.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: { eq: "Tworzenie-kampanii-sprzedazowej-nazwa.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "Tworzenie-kampanii-sprzedazowej-etapy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "Tworzenie-kampanii-sprzedazowej-cele.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(
      relativePath: { eq: "Wlasne-cele-w-karcie-szansy-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(
      relativePath: {
        eq: "Tworzenie-kampanii-sprzedazowej-dodawanie-atrybutow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(
      relativePath: {
        eq: "Sprzedaz-w-systemie-CRM-nowe-atrybuty-do-kampanii-sprzedazowej.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie9: file(
      relativePath: {
        eq: "Tworzenie-atrybutu-dla-kampanii-sprzedazowej-w-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie10: file(
      relativePath: {
        eq: "Dodawanie-utworzonego-atrybutu-do-kampanii-sprzedazowej.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie11: file(
      relativePath: {
        eq: "System-CRM-zarzadzanie-szansami-sprzedazy-wlasne-atrybuty.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie12: file(
      relativePath: { eq: "System-CRM-wiele-kampanii-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie13: file(
      relativePath: { eq: "Prosty-system-CRM-dodawanie-szansy-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie14: file(
      relativePath: { eq: "Prosty-system-CRM-dodana-szansa-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie15: file(
      relativePath: { eq: "System-CRM-karta-szansy-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie16: file(
      relativePath: {
        eq: "Prosty-system-CRM-przesuwanie-szansy-pomiedzy-etapami.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const SzanseSprzedaży = ({ data }) => {
  return (
    <HelpPage
      name="Kampanie sprzedażowe"
      metaTitle="Pomoc - kampanie sprzedażowe i szanse sprzedaży - QuickCRM"
      metaDescription="Szanse sprzedaży w systemie CRM - pomoc • Jak tworzyć kampanie sprzedażowe? • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          W QuickCRM możesz stworzyć dowolną liczbę kampanii i szans sprzedaży.
          Każdą z nich możesz także dowolnie dopasować do właściwości procesów
          sprzedażowych w Twojej firmie. W systemie domyślnie skonfigurowany
          jest widok kampanii sprzedażowej złożonej z czterech etapów, ale
          możesz dowolnie zarządzać ich ilością. Dla każdego z etapów możesz
          także stworzyć dowolne cele. Przekonaj się, jak łatwe jest zarządzanie
          sprzedażą w QuickCRM!
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Tworzenie kampanii</Tab>
          <Tab tabFor="zakladka2">[2] Tworzenie etapów</Tab>
          <Tab tabFor="zakladka3">[3] Tworzenie celów</Tab>
          <Tab tabFor="zakladka4">[4] Tworzenie atrybutów</Tab>
          <Tab tabFor="zakladka5">[5] Wyświetlanie kampanii</Tab>
          <Tab tabFor="zakladka6">[6] Dodawanie szansy sprzedaży</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            <strong>Tworzenie kampanii sprzedażowej</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-przesuwanie-szansy-pomiędzy-etapami.png"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />
          <p>
            [1] Aby stworzyć nową kampanię sprzedażową, wybierz “Ustawienia”, a
            następnie “Parametry systemu”.
          </p>
          <p>[2] Następnie przejdź do “Listy kampanii”.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-kamapanii-sprzedażowej-lista-kampanii"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>[3] Kliknij przycisk “dodaj nowy”, aby dodać nową kampanię.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-kampanii-sprzedażowej-nazwa"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />

          <p>[4] Wpisz nazwę dla tworzonej kampanii.</p>

          <p>[5] Uzupełnij opis kampanii.</p>

          <p>[6] Zmień status kampanii na “aktywna”.</p>

          <p>[7] Pamiętaj o zaznaczeniu pola “Wartości aktywne”.</p>

          <p>[8] Zapisz wprowadzone zmiany.</p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-kampanii-sprzedażowej-etapy"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <p>
            [9] Przypisz odpowiednią nazwę do każdego ze stworzonych etapów.
          </p>
          <p>
            [10] Możesz tworzyć dowolną liczbę etapów kampanii. Aby dodać
            kolejne etapy, kliknij przycisk “dodaj nowy”.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka3">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-kampanii-sprzedażowej-cele"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>
            [11] Dla każdeego z etapów kampanii sprzedażowej możesz stworzyć
            dowolne cele.
          </p>
          <p>[12] Aby dodać kolejne cele, kliknij przycisk “dodaj cel”.</p>
          <p>[13] Zapisz wprowadzone zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Własne-cele-w-karcie-szansy-sprzedaży"
            fluid={data.zdjecie6.childImageSharp.fluid}
          />
          <p>
            Stworzone przez Ciebie cele będą widoczne w karcie szansy sprzedaży.
            Cele będą zmieniać się w każdym etapie we wskazany przez Ciebie
            sposób.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka4">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-kampanii-sprzedażowej-dodawanie-atrybutów"
            fluid={data.zdjecie7.childImageSharp.fluid}
          />
          <p>
            [14] Aby do dać atrybuty dla kampanii sprzedażowej, wybierz
            “Ustawienia”, a następnie “Parametry systemu”.
          </p>
          <p>[15] Następnie kliknij “Atrybuty kampanii i szans sprzedaży”.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Sprzedaż-w-systemie-CRM-nowe-atrybuty-do-kampanii-sprzedażowej"
            fluid={data.zdjecie8.childImageSharp.fluid}
          />
          <p>
            [16] W tym miejscu znajduje się lista atrybutów dla kampanii
            sprzedażowych. Aby dodać kolejne atrybuty, kliknij “dodaj nowy”.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie-atrybutu-dla-kampanii-sprzedażowej-w-systemie-CRM"
            fluid={data.zdjecie9.childImageSharp.fluid}
          />
          <p>[17] Nadaj odpowiednią nazwę dla dodawanego atrybutu.</p>
          <p>
            [18] Wybierz rodzaj atrybutu. W systemie dostępnych jest 6 typów
            atrybutów:
            <li>tekst,</li>
            <li>liczba,</li>
            <li>prawda / fałsz,</li>
            <li>data,</li>
            <li>lista wyboru,</li>
            <li>zestaw tagów.</li>
          </p>
          <p>[19] Zaznacz to pole, aby aktywować atrybut.</p>
          <p>
            [20] Wpisz w to pole odpowiednią liczbę, aby ustalić kolejność
            wyświetlania atrybutów w karcie szansy sprzedaży.
          </p>
          <p>
            [21] Następnie dodaj uprawnienia do odczytu i edycji atrybutów.
            Kliknij przycisk “dodaj uprawnienia” i zdecyduj, którzy pracownicy
            mają mieć dostęp do atrybutów.
          </p>
          <p>[22] Zapisz wszystkie wprowadzone zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dodawanie-utworzonego-atrybutu-do-kampanii-sprzedażowej"
            fluid={data.zdjecie10.childImageSharp.fluid}
          />
          <p>
            W następnym kroku musisz przydzielić stworzone atrybuty do
            konkretnej kampanii. Wybierz ponownie “Ustawienia”, “Parametry
            systemu” i “Lista kampanii”. Wybierz edycję kampanii.
          </p>
          <p>[23] Wybierz atrybut, który chcesz dodać do kampanii.</p>
          <p>[24] Kliknij przycisk “dodaj”.</p>
          <p>[25] Zapisz wprowadzone zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="System-CRM-zarządzanie-szansami-sprzedaży-własne-atrybuty"
            fluid={data.zdjecie11.childImageSharp.fluid}
          />
          <p>
            Stworzone przez Ciebie atrybuty pojawią się w karcie szansy
            sprzedaży należącej do wskazanej przez Ciebie kampanii.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka5">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="System-CRM-wiele-kampanii-sprzedaży"
            fluid={data.zdjecie12.childImageSharp.fluid}
          />
          <p>
            [26] Aby wyświetlić szanse sprzedaży należące do konkretnej kampanii
            sprzedażowej, wybierz kampanię z rozwijanej listy.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka6">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-dodawanie-szansy-sprzedaży"
            fluid={data.zdjecie13.childImageSharp.fluid}
          />
          <p>[1] Aby dodać nową szansę sprzedaży, kliknij przycisk “nowa”.</p>
          <p>
            [2] Uzupełnij pole “Nazwa osoby kontaktowej”. Jeśli taka osoba
            kontaktowa nie istnieje jeszcze w systemie, system poinformuje Cię o
            tym, wyświetlając znacznik “NOWA”. Jeśli natomiast osoba kontaktowa
            jest już w systemie, wystarczy, że wpiszesz pierwsze litery, a
            otrzymasz automatyczne podpowiedzi.
          </p>
          <p>
            [3] Uzupełnij pole “Nazwa firmy”. I w tym przypadku system
            poinformuje Cię, jeśli dana firma jest w systemie nowa lub wyświetli
            automatyczne podpowiedzi, jeśli firma znajduje się już w systemie.
          </p>
          <p>[4] Pamiętaj, aby zapisać wszystkie wprowadzone zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-dodana-szansa-sprzedaży"
            fluid={data.zdjecie14.childImageSharp.fluid}
          />
          <p>
            Stworzona przez Ciebie szansa sprzedaży znajduje się w widoku szans
            sprzedaży. Znajduje się ona na etapie, który wybrałeś podczas jej
            tworzenia. Aby przejść do karty szansy sprzedaży, wystarczy kliknąć
            w nią dwa razy.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="System-CRM-karta-szansy-sprzedaży"
            fluid={data.zdjecie15.childImageSharp.fluid}
          />
          <p>
            W karcie szansy sprzedaży odnajdziesz wszystkie ważne informacje,
            które wcześniej zostały tam zgromadzone.
          </p>

          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-przesuwanie-szansy-pomiędzy-etapami"
            fluid={data.zdjecie16.childImageSharp.fluid}
          />

          <p>
            [5] Za pomocą metody “drag and drop” możesz w łatwy sposób przenosić
            szanse sprzedaży pomiędzy etapami.
          </p>
          <p>
            [6] Aby oznaczyć szansę jako wygraną lub utraconą czy też aby usunąć
            szansę sprzedaży, także wystarczy przesunąć ją w odpowiednie
            miejsce.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default SzanseSprzedaży;
